*,
*::before,
*::after {
	box-sizing: border-box;
}

::selection {
	color: white;
	background: #00CBFF;
}

strong {
	font-weight 	: 700;
}

body {
	
	margin         	: var(--header-height) 0 0 0;
	min-height     	: calc(100vh - var(--header-height));
	background 		: #030303;
	display        	: flex;
	flex-direction 	: column;
}

.main-header{}
.main-container{
	background: #030303;
}

.main-container-slider{
	max-height: 400px;
}
.main-footer {
	border-top: 3px solid var(--color-blanco-V95);
	background 		: var(--color-black-02);
	color      		: #FFF;
	padding    		: 2rem 0;
	font-size  		: fontSize(small);
	margin-top 		: auto;
	
	.ul-footer{
		list-style: none;
	}
	.footer-imgs{
		display: flex;
		margin: auto;
		@include from(m){
			display: block;
		}
		img{
			width: auto;
			height: 2rem;
			margin: auto;
			@include from(m){
				width: 140px;
				height: auto;
			}
		}
		
	}
}
.footer-alig{
	margin: auto;
	text-align: center;
	@include from(m){
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}
