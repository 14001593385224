.lorem{
	display: none;
}

.lorem:first-of-type{
	display: block;
}
.tabs-container{
	@include from(m){
		overflow-y: auto;
	}
	@include from(s){
		overflow-y: scroll;
	}
}
.tabs-container__panels{
	width: auto;
	h3{
		text-align: left;
	}
	
	table,th {
		color: white;
		border: solid 1px rgba(0, 0, 0, 0.199);
		padding: 10px;
	}
	td{
		border: solid 1px rgba(0, 0, 0, 0.199);
		padding: 10px;
		color: var(--color-grayLow);
	}
	
	table {
		border-collapse:collapse;
		caption-side:bottom;
	}
	
	caption {
		font-size: 16px;
		font-weight: bold;
		padding-top: 5px;
	}
	td{
		vertical-align: text-top;
	}
}


.main-con{
	.bg-header{
		margin-top: 4rem;
		width: 100%;
		@include from(m){
			margin-top: 4rem;
		}
		@include from(s){
			margin-top: 0.5rem;
		}
		>img{
			width: 100%;
				
				position: absolute;
				top: 9rem;
				left: 0;
				object-fit: cover;
				@include from(l){
					height: auto;
					top: 5rem;
				}
				@include from(m){
					top: 4rem;
					height: 350px;
				}
				@include from(s){
					height: 450px;
				}	
		}}
.main-cont{
		.container-cont{
			text-align: center;
			max-width: 100%;
			height: auto;
			box-sizing: border-box;
			border-radius: 10px;
			margin: 0 auto 0;
			position: relative;
			z-index: 1;

			.text-header{
				font-family: 'Basketball';
				color: var(--color-blanco-V95);
				margin-bottom: 3rem;
				h1{
					font-weight: 500;
					font-size: 29px;
					@include from(m){
						font-size: 64px;
					}
				}
				

			}
			.button-naranja{
				font-family:'Montserrat', sans-serif;
				font-weight: 600;
				font-size: 16px;
				color: black;
				background: var(--color-Ama-01);
				padding: 12px 70px;
				border-width: 0px;
				border-radius: 10px;
				border: 1px solid;
				transition: 0.2s;
				border-color: transparent;
				cursor: pointer;
				a{
					text-decoration: none;
				}
			&:hover{
				border-color: var(--color-Ama-01);
				color: rgb(233, 233, 233);
				background-color: var(--color-Ama-02);
			}
			}

		
		
			
		
		
		
			


			@include from(s){
				margin: 0 auto;
			}
			h2{
				color: var(--color-skyLow);
			}

			.sub-cont{
				h2{
					color: white;
				}
				.container-cont{
					color: white;
					background-color: var(--color-skyLow);
				}
				
			}
			.text-cont{
				font-size: 1.1rem;
				
				@include from(m){
					text-align: left;
					}
					@include from(s){
						text-align: center;
					}
			}

			

			}
			.font-low{
				color: var(--color-grayLow);
				
			}

			.img-metodosPay{
				width: 99%;
				border-radius: 6px;
			}

			.btn-banca-nav{
				cursor: pointer;
				position: relative;
				list-style: none;
				-webkit-box-flex: 1;
				-ms-flex: auto;
				flex: auto;
				background-color: var(--color-sky);
				margin: 10px 0px;
				padding: 15px 5%;
				color: white;
				border-radius: 5px;
				border: 2px;
				border-color: var(--color-skyLow);
				transition: .5s;
				transform: scale(.9);
			}
			
			.btn-banca-nav:hover{
				transform: scale(1);
				background-color: var(--color-skyLow); /* Green */
				color: white;
			  }
		}
	}

	

