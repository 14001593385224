.tabs-container {
	width: 100%;
	position: relative;
	margin: 5em auto;
		table{
			width: 100%;
		}
	&__tabs {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
	}

	&__tab {
		background: #eee;
		color: #666;
		line-height: 2;
		padding: 0 1em;
		border: 1px solid #ccc;
		margin-right: 1px;
		border-bottom: none;
		border-radius: 4px 4px 0 0;
		position: relative;
		top: 1px;
		cursor: pointer;

		&.is-active {
			background: #fff;
			color: #111;
			position: relative;
			z-index: 2;
		}
	}

	&__panels {
		position: relative;
		z-index: 1;
		padding: 1em;
		@include from(s){
			border-right: 0px;
			border-bottom: 1px solid #ccc;
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
		}
		@include from(m){
			border: 1px solid #ccc;
		}
	}

	&__panel {
		position: absolute;
		left: -1000%;

		&.is-active {
			position: relative;
			left: 0;
		}
	}
}
