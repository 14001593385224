//layout

:root {
  --header-height 		: 3rem;
  @include from(l) {
    --header-height 	: 4rem;
  }
	//native color vars
	--color-first      	: #007BDF;
	--color-sky			: #416fcf;
	--color-skyLow		: #233c74;
	--color-grayLow		: #bbbbbb;
	--color-third      	: #ffff4d;
	--color-background  : white;
	--color-Azul01		: #0B1C47;
	--color-red-low		: #a77878;
	--color-red-02		: #904343;
	--color-red-03      : #d50000;
	--color-black		: #0e1111;
	--color-black-02    : #0c0c0c;
	--color-blanco-V95	: #F1F1F1;
	--color-Amarillo-01	: #CCFF11;
	--color-Celeste-01  : #3591FF;
	--color-Celeste-02  : #80BAFF;
	--color-Ama-01	: #ffff4d;
	--color-Ama-02	: #97972d;
	--color-Verde-01	: #028700;
	--color-Verde-02	: #015900;
}
// Sass Color Vars
$color-first 			: var(--color-first			);
