.top-nav{
	font-size: 14px;
	border-bottom 	: 1px solid #DFE0E0;
	background    	: var(--color-black);
	position      	: fixed;
	top           	: 0;
	width         	: 100%;
	left          	: 0;
	display 		: flex;
	justify-content : center;
	z-index: 2;
	padding: 1.2rem 0.5rem;
	@include to(m){
		border-bottom: transparent;
	}
	
	&__container{
		display 		: flex;
		justify-content : space-between;
		height        	: var(--header-height);
		width: 100%;
		max-width: 1250px;
		@include from(l){
			padding: 0px;
		}
		@include from(m){
			padding: 0 10px;
		}
		@include from(l){
			padding: 0px;
		}
		
	}

	&__link{
		align-self: center;
		width: 200px;
		text-align: left;
		img{
			padding-left: 15%;
			width: auto;
			height: auto;
		}

	}
	&__logo{
		height: 5rem;
		width: auto;
		display: block;
		@include from(l){
			height: 5rem;
		}
		@include from(m){
			height: 6rem;
		}
	}
	&__menu{
		
		font-size: 0.8rem;
		
		@include fromTo(s,l){
			
			position 		: fixed;
			padding 		: 4rem 10px;
			height 			: 100vh;
			width 			: 260px;
			background-color: var(--color-black);
			transition 		: right .2s;
			overflow-y 		: auto;
			border-top 		: 0.1px solid var(--color-blanco-V95);
			border-left 	: 0.1px solid var(--color-blanco-V95);
			top 			: 5.3rem;
			right 			: -260px;
			z-index: 1;
			&.show-top-nav {
				right 		: 0;
			}
		}
		@include from(l){
			display: flex;
		}
		@include from(l){
			align-self: center;
			margin: 0;
			padding: 0;
		}
	}
	&__li{
		
		@include fromTo(s,l){
			display: block;
			width: auto;
			margin: 5px;
		}
		width: 128px;
		margin: 0 5px;
		list-style-type: none;
		a{
			margin: 0;
			
		}
		
	}
	&__anchor{
	    font-size: 11px;
		text-align: center;
		padding: 20px 0px;
		font-weight: 700;
		background-color: transparent;
		border-radius: 8px;
		border: 0.1px solid var(--color-blanco-V95);
		color: var(--color-blanco-V95);
		@include from(s){
			display: block;
		}
		@include from(l){
			display: block;
			border-right: 0.1px solid var(--color-blanco-V95);
			padding: 8px 30px;
			padding-top: 9px;
		}

		

	}



	&__mb-iconMenu {
		position: relative;
		align-self: center;
		top: 3px;
		padding: 0;
		
		span{
			&.hamburger-inner{
				background: white;
				&::after{}
				&::before{}
				&::after, &::before{
					background: white;
				}
			}
		}
		&:focus {
			outline: 0 !important;
			-moz-outline-style: none !important;
			outline: thin dotted;
			outline: 5px auto -webkit-focus-ring-color;
			outline-offset: -2px;
		}
		@include from(l){
			display: none;
		}
	}
	.top-nav__anchor:hover{
		color: var(--color-red-02);
		border-color: var(--color-red-low);
		text-decoration: none;
		
	}
	.btn-ml{
		white-space: nowrap;
		display: block;
		@include from(m){
			display: block;
		}
		@include from(s){
			display: none;
		}
	}
	
.btn-top-nav{
	ul{
		margin: 0;
		padding: 0;
	}
		font-size: 13px;
		font-weight: 600;
		position: relative;
		list-style: none;
		-webkit-box-flex: 1;
		-ms-flex: auto;
		flex: auto;
		width: 125px;
		background-color: var(--color-blanco-V95);
		padding: 10px 20px;
		border-width: 1.8px;
		border-color: var(--color-blanco-V95);
		color: black;
		border-radius: 9px;
	
		cursor: pointer;
		transition: .2s;
		transform: scale(1);

		@include from(m){
			margin: 0;
		}
		@include from(l){
			margin: 10px 0px;
		}

		a{
			text-decoration: none;
		}
}
		.btn-top-nav:hover{
			transform: scale(1);
			background-color: rgb(124, 124, 124); /* Green */
			color: var(--color-grayLow);
		  }
		.btn-dark:hover{
			background-color: var(--color-Verde-02); /* Green */
			color: var(--color-Verde-01);
			border-color: var(--color-Verde-01);
		}
		.btn-dark{
			margin-left: 0.7rem;
			border-color: transparent;
			color: var(--color-blanco-V95);
			background-color: var(--color-Verde-01);
			font-weight: bold;
		}
}

.btn-m-nav{
	ul{
		margin: 0;
		padding: 0;
	}
	background-color: var(--color-black);
		margin: auto;
		width: 100%;
		border-top: 25px solid white;
		border-bottom: 3px solid white;
		display: none;
		text-align: center;

		@include fromTo(s,l){
			display: block;
		}
		@include from(m){
			display: none;
		}
	.btn-top-nav{
		font-size: 18px;
		font-weight: bold;
		position: relative;
		list-style: none;
		-webkit-box-flex: 1;
		-ms-flex: auto;
		flex: auto;
		background-color: var(--color-blanco-V95);
		margin: 10px 0px;
		padding: 14px 30px;
		border-radius: 10px;
		border: 1px solid var(--color-blanco-V95);
		transition: .2s;
		transform: scale(.9);
		color: black;
	}
	.btn-dark{
		border-color: transparent;
		color: var(--color-blanco-V95);
		background-color: var(--color-Verde-01);
	}
	.btn-top-nav:hover{
			background-color: rgb(124, 124, 124); 
			color: var(--color-grayLow);
		}
	.btn-dark:hover{
		background-color: var(--color-Verde-02); 
		color: var(--color-Verde-01);
		border-color: var(--color-Verde-01);
	}
}


