.container-cont{
	
	.container-cardApostar{
		position: relative;
		text-align: center;
		width: 100%;
		margin-top: 5rem;
		border-radius: 15px;
		background-color: var(--color-blanco-V95);	
		.card-team{
			position: relative;	
			.text-cardTeam{
				color: var(--color-blanco-V95);
				
				z-index: 1;
				position: absolute;
				width: 100%;
				
				.colum-team{
					grid-column-gap: inherit;
					margin-top: 38px;
					padding-left: 38px;

					.col-2{
						padding-right: 2rem;
					}
					.col-1{
						margin-right: 1rem;
					}
					
					@include from(mx){
						margin-top: 30px;
						padding-left: 30px;
					}
					@include from(m){
						margin-top: 18px;
						padding-left: 35px;
						
					}	
					@include from(mz){
						margin-top: 30px;
						padding-left: 38px;
					}
					
					@include from(l){
						margin-top: 38px;
						padding-left: 38px;
					}
				}
			}
			
		}
		@include from(s){
			margin-top: 3rem;
		}

		@include from(m){
			margin-top: 5rem;
		}

		h3{
			padding: 0;
			margin: 0;
			margin-top: 0.5rem;
		}
		img{
			@include from(s){
				height: 135px;
				
			}
			@include from(m){
				height: 130px;
				
			}
			@include from(l){
				height: 170px;
				
			}
		}
		button{
			font-family: 'Montserrat', sans-serif;
			font-weight: bold;
			font-size: 14px;
			color: var(--color-blanco-V95);
			background: var(--color-Verde-01);
			padding: 12px 70px;
			border-width: 0px;
			border-radius: 10px;
			z-index: 1;
			position: absolute;
			bottom: -25px;
			cursor: pointer;
			&:hover{
				background-color: var(--color-Verde-02);
			}
			@include from(s){
				padding: 13px 30px;
				left: 33.33%;
			}
			@include from(m){
				padding: 12px 35px;
				left: 35.33%;
			}
			@include from(l){
				padding: 15px 45px;
				left: 37.33%;
			}
			
		}
	}

}
