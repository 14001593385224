.main-casino{
	
	margin-top: 5rem;
	color: var(--color-blanco-V95);
	.container-cont{
		border-radius: 10px;
		padding: 1rem;
		border: 2px solid var(--color-blanco-V95);
	}
}



.container-gamesC{
	.ed-grid{
		gap: inherit;
	}
	.text-cont{
		h3{
			
			margin: auto;
			font-size: 1.8rem;
		}
		@include from(s){
			font-size: 1.5rem;
			text-align: center;
		}
		@include from(l){
			text-align: left;
		}
	}

	.img-casino{
			border: 1px solid black;
			border-radius: 4px;
			box-shadow: 0 0 15px 0px #333;
			transition: 0.6s;
			transform: scale(0.9);
	}
	.card-itemCasino{
		position: relative;
		.buttons-itemCasino{
			opacity: 0;
			width: 100%;
			z-index: 4;
			text-align: center;
			color: white;
			position: absolute;
			z-index: 1;
			bottom: 30px;
			transition: 0.6s;
			h3{
				background: rgba(0, 0, 0, 0.377);
			}
			button{
				cursor: pointer;
				background-color: var(--color-red-03);
				margin: 10px 0;
				padding: 10px;
				color: #fff;
				border-radius: 5px;
				border: 2px;
				border-color: var(--color-red-02);
				&:hover{
					background-color: var(--color-red-02);
				}
			}
			
		}
		&:hover{
			.buttons-itemCasino{
				opacity: 1;
			}
			.img-casino{
				transform: scale(1);
			}
			
		}
		
	}
}

