.box-color{
	background: #cccccc;
	color: #666666;
	border: 1px solid white;
	padding: 5px 15px;
}

.section-divider{
	padding-bottom: 30px;
}
img {
	border: 0;
	display: block;
	width: 100%;
	max-width: 100%;
	height: auto;
}

.table-responsive{
	@include to(m){
	overflow-x: scroll;}
}
.container-legals{
	color: white;
	#sportsRules th {
		color: #fff;
		font-size: 15px;
		font-weight: 500;
		background-color: var(--color-red-03);
		width: fit-content;
		padding: 5px 22px;
		border: 1px solid #fff;
		border-collapse: collapse;
		text-align: center;
	}
	#sportsRules td {
		border: 1px solid var(--color-red-low);
		font-size: 14px;
		color: white;
		padding: 0 0 0 20px;
	}
	.container-bonificaciones{
		table th{
			vertical-align: bottom;
		}
		.table td, .table th {
			padding: .75rem;
			vertical-align: top;
			border-top: 1px solid #dee2e67e;
		}
	}
}
.footer-main{
	justify-items: center;
	@include to(m){

	}
	li{
		list-style: none;
	}
	ul{
		padding: 10px;
		@include to(m){
			width: 150px;
		}
	}
	h3
	{
		margin: 0;
		font-weight: 100;
	}
	
}

.sidebar-first {
  margin-bottom : 1em;
}

.no-marging{
	margin-top: 0;
	margin-bottom: 0;
}

.bottom-space{
	margin-bottom: 1em;
}
.foot-icon{
	margin-left: 5px;
}
