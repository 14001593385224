.container-LoginRegister{
	text-align: center;
	margin-top: 4rem;
	background-color: var(--color-black );
	padding: 1.8rem 2.5rem 1.8rem 2.5rem;
	border-radius: 10px 10px 0 0;
	@include to(m){
		padding: 1.8rem 0.5rem;
	}
	.ed-container{
		justify-content: space-around;
		padding: 1rem;
	}
		.container-top{
		background-color: var(--color-black-02 );
		border-radius: 10px;
		border: 1px solid var(--color-black);
		
	}

	.select-LR{
		color: var(--color-blanco-V95);
		font-weight: bold;
		font-family: 'Basketball';
		font-size: larger;
		font-weight: 100;
		background-color: var(--color-red-03);
		padding: 0.8rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		width: 350px;
		box-shadow: 2px 3px 6px 0px black;;
	}

	.select-l{
		border: none;
		border-top: 4px solid var(--color-red-low);
		cursor: pointer;
	}
	.select-r{
		border: none;
		border-top: 4px solid var(--color-red-low);
		cursor: pointer;
	}
	
		
	
}

.container-formulario{
	height: 100%;
	text-align: center;
	background-color: var(--color-black );
	padding: 0rem 2.5rem 1.8rem 2.5rem;
	border-radius: 0px 0px 10px 10px;
	margin-bottom: 1rem;
	@include to(m){
		padding: 1.8rem 0.5rem;
	}
	.ed-container{
		justify-content: space-around;
		padding: 1rem;
	}
		.container-top{
		background-color: var(--color-black-02 );
		border-radius: 10px;
		border: 1px solid var(--color-black);
		
	}
	.form-login{
	.form-grop-login{
		
		color: var(--color-blanco-V95);
		align-content: center;
		flex-direction: column;
		justify-content: center;
		text-align: left;
		padding: 4rem;
		width: 100%;
		p{
			margin-bottom: 1rem;
			margin-top: 5px;
		}
		.separador{
			
			margin-bottom: 1rem;
			margin-top: 5px;
		}
		input{
			color: black;
		}

		.btn-summitLogin{
			font-size: 18px;
			margin-top: 1rem;
			width: 100%;
			color: var(--color-Azul01);
			padding: 0.5rem;
			cursor: pointer;
		}
	}}

	.form-register{
		display: none;
	.form-grop-register{
		
		color: var(--color-blanco-V95);
		align-content: center;
		flex-direction: initial;
		justify-content: center;
		text-align: left;
		padding: 4rem;
		width: 100%;
		p{
			margin-bottom: 5px;
			margin-top: 5px;
		}
		input{
			border: 1px solid var(--color-Amarillo-01);
			background-color: var(--color-Azul01);
			padding: 0.2rem;
			color: var(--color-blanco-V95);
		}
		

		.col-1{
			margin-right: 2rem;
		}
		.col-2{
			margin-right: 2rem;
		}
	}
	.btn-summitRegister{
		font-size: 18px;
		width: 20%;
		color: var(--color-Azul01);
		padding: 0.5rem;
	}
}
}
